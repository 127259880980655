.container {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  height: 100%;
  overflow: hidden;
}

/* adjust width of editor to 50% */
:global ._3RpRzn6ol7oFMRMrWdw3Iu {
  width: 50%;
}

/* set a break in between long words and continue on next line */
:global .CodeMirror-linewidget {
  overflow-wrap: break-word;
}
.CodeMirror {
  border: 1px solid #eee;
  height: auto !important;
}

.CodeMirror-linewidget {
  margin: 10px;
  overflow: hidden;
  border: 1px solid #a3a3a3;
  border-radius: 10px;
  max-width: 800px;
}

@media screen and (min-width: 800px) {
  .CodeMirror-linewidget {
    width: 80%;
  }
}
