.sidebar-list-items .active {
  color: #0041ca;
}
.sidebar-list-items li {
  @apply py-1 cursor-pointer;
}
.sidebar-list-items .active:after {
  content: "";
  display: inline-block;
  @apply rounded-full w-[10px] h-[10px] ml-2;
  background: #0041ca;
}
