.pagination-btn {
    @apply m-auto mb-3
}
.pagination-btn * {
    color: #000000;
}
.pagination-btn li {
    @apply mx-2 border-2 rounded-full h-[20px] w-[20px] flex items-center justify-center p-4
}
.pagination-active *{
    color: #fff !important;
}