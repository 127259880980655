.light-navbar *{
    font-family: 'Poppins' !important;
}
.light-navbar a {
    @apply text-sm;
    color: var(--black-color);
}
.forms-nav-items a.active, .light-navbar a.active {
    color: var(--primary-color);
    position: relative;
}
.forms-nav-items a.active::after, .light-navbar a.active::after {
    content: "";
    display: block;
    position: absolute;
    left: 40%;
    width: 7px;
    height: 7px;
    background-color: var(--primary-color);
    border-radius: 50%;
}
