@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #0041ca;
  --white-color: #efefef;
  --grey-color: #bfbfbf;
  --cyan-color: #0eb7ed;
  --black-color: #393939;
  --muted-color: #c4c4c4;
}

* {
  font-family: "Inter";
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #fff;
  background-color: #f7f7f7;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

html {
  font-size: 100%;
  scroll-behavior: smooth;
}

/*16px*/

body {
  background: white;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.75;
  color: #000000;
}

p {
  margin-bottom: 1rem;
}

.font-roboto * {
  font-family: "Roboto", sans-serif !important;
}

.font-poppins * {
  font-family: "Poppins", sans-serif !important;
}

.font-roboto-mono * {
  font-family: "Roboto Mono", monospace, sans-serif !important;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 3rem 0 1.38rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text_small {
  font-size: 0.8rem;
}

.grey-border {
  border: 1px solid hsl(0, 0%, 80%);
}

/* SCROLL BAR */
::-webkit-scrollbar {
  width: 0.6rem;
  background-color: #ddee;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: #b5b5c5;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background: #9595a7;
}

/* global classnames for colors */
.primary-color {
  color: var(--primary-color);
}

.primary-bg {
  background-color: var(--primary-color);
}

.grey-color {
  color: var(--grey-color);
}

.grey-bg {
  background-color: var(--grey-color);
}

.light-grey-bg {
  background-color: var(--white-color);
}

.cyan-color {
  color: var(--cyan-color);
}

.cyan-bg {
  background-color: var(--cyan-color);
}

.black-color {
  color: #393939;
}

.black-color-all * {
  color: #393939;
}

.black-bg {
  background-color: #393939;
}

.text-muted {
  color: var(--muted-color) !important;
}

.primary-gradient {
  background: linear-gradient(126deg, #0041ca 0%, #3266d8 51%, #1fc4f9 100%);
}

a {
  color: #fff;
  font-weight: 500;
}

a:hover {
  color: #dbd9d9;
}

input:focus {
  border-width: 2px;
}

/* Snippets */
.snippet-skeleton-card {
  box-shadow: 0 0 6px 3px #ebebfd;
}

/* RESPONSIVENESS STYLES */

/* For tablets */
@media screen and (max-width: 375px) {
  .featured-container .header {
    @apply flex-col;
  }
  .featured-container .languages-tags {
    @apply w-10/12;
  }

  /* Forms layout */
  .forms-layout-container .forms-banner {
    @apply hidden;
  }

  .forms-layout-container .forms-part {
    @apply w-full;
  }

  .forms-layout-container .form-container {
    @apply w-10/12;
  }

  /* Forms layout */

  .under-banner {
    display: none;
  }

  .banner-container {
    @apply flex items-center justify-center flex-col;
  }

  .banner-container h1,
  p {
    @apply text-center;
  }

  .banner-container p {
    @apply text-sm;
  }

  .banner-container h1 {
    @apply text-[1.2rem];
  }

  .ri-arrow-drop-right-line {
    display: none;
  }

  .testimonials-container-mobile {
    @apply p-0;
  }

  .input-wrapper {
    @apply w-full;
  }

  .talk-to-us {
    @apply w-11/12;
  }

  footer {
    @apply justify-between flex-col;
    padding: 1rem 2.2rem !important;
  }

  footer .logo-section {
    @apply mb-5;
  }

  footer .links-section {
    @apply w-full;
  }

  .mobile-footer-right {
    @apply flex-col;
  }
}

/* For desktops */
@media screen and (max-width: 393px) {
  .snippet-card-container {
    width: 96vw;
  }
  .snippet-card-skeleton {
    @apply w-full;
  }
}
@media screen and (max-width: 585px) {
  .editor-container {
    width: 92%;
  }
  .related-snippets-container {
    width: 92%;
    padding: 2rem;
  }
  .commentContainer {
    width: 82%;
  }
  .page-container {
    @apply flex-col px-3;
  }
  .page-container .right,
  .page-container .left {
    @apply w-full;
  }
  .waitlist-info {
      height: auto;
      width: 100%;
  }
}


@media screen and (max-width: 700px) {
.forms-layout-container .forms-banner {
  @apply hidden;
}

.forms-layout-container .forms-part {
  @apply w-full;
}

.forms-layout-container .form-container {
  @apply w-10/12;
}}