/* Codemirror */
.react-codemirror2 {
  height: 100%;
}

.CodeMirror {
  height: 100%;
}
.commentContainer *{
    font-family: "Inter" !important;
}

.comment__toolbar {
  display: none !important;
}
.react-mde {
    border: none !important;
}
.comment__textarea {
    border: none;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
}

.comment__textarea:hover {
  /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
}
