.forms-banner {
  background: rgba(0, 65, 202, 0.81);
  background: linear-gradient(
      180deg,
      rgba(0, 65, 202, 0) 0%,
      rgba(0, 65, 202, 0.81) 100%
    ),
    url("../assets/images/forms-banner.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.forms-nav-items a {
  @apply text-sm;
}
