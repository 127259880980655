.section-header__p::before, .section-header__p::after {
    content: "";
    display: inline-block;
    width: 45.5px;
    margin-right: 0.4rem;
    border: 1.7px solid var(--primary-color);
    background-color: var(--primary-color)
}
.section-header__p::after {
    margin-left: 0.4rem;
}