.review-page * {
  font-family: Poppins;
}
.editor-container {
  min-height: 70vh !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  overflow: hidden;
}
.preformattedDescription {
    text-wrap: auto;
}
